
import { Vue, Component, Prop } from 'vue-property-decorator'
import MainWithSidePanelLayout from '@/layouts/MainWithSidePanelLayout.vue'
import MainWithSidePanelLayoutNavigation from '@/components/MainWithSidePanelLayoutNavigation.vue'
import FinanceChargeDispute from '@/components/FinanceChargeDispute.vue'

@Component({
  components: {
    MainWithSidePanelLayout,
    MainWithSidePanelLayoutNavigation,
    FinanceChargeDispute,
  },
})
export default class Finance extends Vue {
  @Prop({ type: String, default: 'charge-dispute' }) readonly mode: string

  links = [
    {
      mode: 'charge-dispute',
      icon: 'credit_card_off',
      value: FinanceChargeDispute,
    },
  ]

  get component() {
    return (
      this.links.find((component) => this.mode === component.mode)?.value ||
      null
    )
  }
}
